exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-gb-404-js": () => import("./../../../src/pages/en-gb/404.js" /* webpackChunkName: "component---src-pages-en-gb-404-js" */),
  "component---src-pages-en-gb-login-js": () => import("./../../../src/pages/en-gb/login.js" /* webpackChunkName: "component---src-pages-en-gb-login-js" */),
  "component---src-pages-en-gb-search-js": () => import("./../../../src/pages/en-gb/search.js" /* webpackChunkName: "component---src-pages-en-gb-search-js" */),
  "component---src-pages-es-es-404-js": () => import("./../../../src/pages/es-es/404.js" /* webpackChunkName: "component---src-pages-es-es-404-js" */),
  "component---src-pages-es-es-buscar-js": () => import("./../../../src/pages/es-es/buscar.js" /* webpackChunkName: "component---src-pages-es-es-buscar-js" */),
  "component---src-pages-es-es-iniciar-sesion-js": () => import("./../../../src/pages/es-es/iniciar-sesion.js" /* webpackChunkName: "component---src-pages-es-es-iniciar-sesion-js" */),
  "component---src-pages-fr-fr-404-js": () => import("./../../../src/pages/fr-fr/404.js" /* webpackChunkName: "component---src-pages-fr-fr-404-js" */),
  "component---src-pages-fr-fr-login-js": () => import("./../../../src/pages/fr-fr/login.js" /* webpackChunkName: "component---src-pages-fr-fr-login-js" */),
  "component---src-pages-fr-fr-recherche-js": () => import("./../../../src/pages/fr-fr/recherche.js" /* webpackChunkName: "component---src-pages-fr-fr-recherche-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-it-404-js": () => import("./../../../src/pages/it-it/404.js" /* webpackChunkName: "component---src-pages-it-it-404-js" */),
  "component---src-pages-it-it-accedi-js": () => import("./../../../src/pages/it-it/accedi.js" /* webpackChunkName: "component---src-pages-it-it-accedi-js" */),
  "component---src-pages-it-it-cerca-js": () => import("./../../../src/pages/it-it/cerca.js" /* webpackChunkName: "component---src-pages-it-it-cerca-js" */),
  "component---src-pages-it-it-prodotti-tap-to-pay-tap-to-pay-su-android-inizio-richiesta-js": () => import("./../../../src/pages/it-it/prodotti/tap-to-pay/tap-to-pay-su-android/inizio-richiesta.js" /* webpackChunkName: "component---src-pages-it-it-prodotti-tap-to-pay-tap-to-pay-su-android-inizio-richiesta-js" */),
  "component---src-pages-it-it-prodotti-tap-to-pay-tap-to-pay-su-iphone-inizio-richiesta-js": () => import("./../../../src/pages/it-it/prodotti/tap-to-pay/tap-to-pay-su-iphone/inizio-richiesta.js" /* webpackChunkName: "component---src-pages-it-it-prodotti-tap-to-pay-tap-to-pay-su-iphone-inizio-richiesta-js" */),
  "component---src-pages-it-it-support-temp-js": () => import("./../../../src/pages/it-it/support-temp.js" /* webpackChunkName: "component---src-pages-it-it-support-temp-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-custom-page-template-js": () => import("./../../../src/templates/CustomPageTemplate.js" /* webpackChunkName: "component---src-templates-custom-page-template-js" */),
  "component---src-templates-faq-category-template-js": () => import("./../../../src/templates/FaqCategoryTemplate.js" /* webpackChunkName: "component---src-templates-faq-category-template-js" */),
  "component---src-templates-faq-template-js": () => import("./../../../src/templates/FaqTemplate.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-job-position-template-js": () => import("./../../../src/templates/JobPositionTemplate.js" /* webpackChunkName: "component---src-templates-job-position-template-js" */),
  "component---src-templates-whitepaper-template-js": () => import("./../../../src/templates/WhitepaperTemplate.js" /* webpackChunkName: "component---src-templates-whitepaper-template-js" */)
}

